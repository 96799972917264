<template>
  <div id="problem">
    <p class="title">选择问题类型</p>
    <div class="choice-box">
      <div
        :class="activeIndex === index ? 'btn active' : 'btn'"
        v-for="(item, index) in type"
        :key="index"
        @click="changeItem(index)"
      >{{item}}
      </div>
    </div>

    <div class="area-box">
      <textarea
        v-model="reason"
        @input="reasonInput"
        placeholder="请详细描述下您的问题，还可以通过下方上传图片，方便我们更好的为您解决问题。"
      />
      <p class="tips">{{reasonNum}}</p>
    </div>

    <p class="title">上传图片（选填）</p>
    <van-field name="uploader" style="padding-top:2.13* 3.75px; box-sizing:border-box;">
      <template #input>
        <van-uploader v-model="uploader"/>
      </template>
    </van-field>

    <p class="title tel">联系方式（选填）</p>
    <input
      type="number"
      class="phone-input"
      v-model="phone"
      placeholder="可以填写您的手机号码，以便于我们对您进行回访"
      oninput="if(value.length>11)value=value.slice(0,11)"
    />

    <div class="submit-btn" @click="submit">提交</div>
  </div>
</template>

<script>
  import {Dialog, Toast} from "vant";
  import {feedbackProblem} from "../api/requist";

  export default {
    components: {},
    props: [],
    data() {
      return {
        flag: true,
        reason: "",
        reasonNum: "0/200",
        activeIndex: 0,
        phone: "",
        type: ["功能建议", "使用体验", "修改意见", "其他"],
        uploader: []
      };
    },
    created() {
    },
    mounted() {
    },
    watch: {},
    computed: {},
    methods: {
      changeItem(index) {
        this.activeIndex = index;
      },
      reasonInput() {
        if (this.reason.length >= 200) {
          this.reason = this.reason.slice(0, 200);
        }
        this.reasonNum = this.reason.length + "/200";
      },

      submit() {
        if (!this.flag) {
          return;
        }

        let that = this;
        if (this.reason === "") {
          return Toast("请描述您的问题");
        }

        if (this.phone !== "" && !/^1[34578]\d{9}$/.test(this.phone)) {
          return Toast("请填写正确的手机号");
        }

        Dialog.confirm({
          title: "确认提交吗",
          message: ""
        })
          .then(() => {
            // let uploader = [];
            // this.uploader.forEach(item => {
            //   uploader.push(item.file);
            // });
            this.flag = false;
            let formData = new FormData();
            formData.append("merch_id", localStorage.getItem("merch_id"));
            formData.append("feedback_type", this.activeIndex + 1);
            formData.append("feedback_content", this.reason);
            formData.append("contact_information", this.phone);
            this.uploader.forEach(item => {
              formData.append("relevant_photo[]", item.file);
            });

            feedbackProblem(formData).then(res => {
              if (res.code === 200) {
                Toast({
                  message: res.message,
                  onClose: function () {
                    that.$router.push({
                      path: "/index"
                    });
                    that.flag = true;
                  }
                });
              } else {
                Toast({
                  message: res.message,
                  onClose: function () {
                    that.flag = true;
                  }
                });
              }
            });
          })
          .catch(() => {
          });
      }
    }
  };
</script>
<style lang="scss">
  @import '../common/css/theme.scss';

  #problem {
    height: 100vh;
    overflow-y: scroll;
    background: rgba(255, 255, 255, 1);
    box-sizing: border-box;

    .title {
      padding: 0 4.27* 3.75px;
      margin-top: 4.27* 3.75px;
      margin-bottom: 2.13* 3.75px;
      text-align: left;
      font-size: 4.27* 3.75px;
      font-weight: bold;
      line-height: 6.4* 3.75px;
      color: rgba(48, 49, 51, 1);
    }

    .choice-box {
      padding: 0 4.27* 3.75px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: space-between;

      .btn {
        width: 28.53* 3.75px;
        height: 7.47* 3.75px;
        background: rgba(240, 242, 245, 1);
        border-radius: 4* 3.75px;
        font-size: 3.73* 3.75px;
        line-height: 7.47* 3.75px;
        text-align: center;
        color: rgba(96, 98, 102, 1);
        background: rgba(240, 242, 245, 1);
        margin-bottom: 2.13* 3.75px;

        &.active {
          background: $theme-color;
          color: rgba(255, 255, 255, 1);
        }
      }
    }

    .area-box {
      margin: 0 auto;
      width: calc(100vw - 16px * 2);
      height: 37.33* 3.75px;
      background: rgba(245, 247, 250, 1);
      border-radius: 1* 3.75px;
      padding: 3.2* 3.75px;
      box-sizing: border-box;

      textarea {
        width: 100%;
        height: 21.33* 3.75px;
        font-size: 3.73* 3.75px;
        font-weight: 400;
        line-height: 4.27* 3.75px;
        color: #303133;
        outline: none;
        border: none;
        resize: none;
        background: rgba(245, 247, 250, 1);
        color: #909399;
        font-size: 3.73* 3.75px;

        &::placeholder {
          color: #909399;
          font-size: 3.73* 3.75px;
        }
      }

      .tips {
        text-align: right;
        margin-top: 5.07* 3.75px;
        font-size: 3.2* 3.75px;
        font-weight: 400;
        line-height: 4.27* 3.75px;
        color: rgba(48, 49, 51, 1);
      }
    }

    .van-uploader__preview,
    .van-image,
    .van-uploader__upload {
      height: 25.33* 3.75px;
      width: 25.33* 3.75px;
    }

    .van-uploader__preview,
    .van-uploader__upload {
      margin-right: 2.13* 3.75px;
      margin-bottom: 2.13* 3.75px;
    }

    .van-cell:not(:last-child)::after {
      border: none;
    }

    .van-cell {
      padding: 0 4.27* 3.75px;
    }

    .title.tel {
      margin-top: 2.27* 3.75px;
    }

    .phone-input {
      padding: 8px 12px;
      box-sizing: border-box;
      margin: 0 16px;
      width: calc(100vw - 16px * 2);
      border: none;
      outline: none;
      background: rgba(245, 247, 250, 1);
      opacity: 1;
      border-radius: 1* 3.75px;
      color: rgba(144, 147, 153, 1);
      font-size: 3.73* 3.75px;

      &::placeholder {
        color: rgba(144, 147, 153, 1);
        font-size: 3.73* 3.75px;
      }
    }

    .submit-btn {
      margin: 14.4* 3.75px auto 0;
      width: 91.47* 3.75px;
      height: 11.73* 3.75px;
      background: $theme-color;
      line-height: 11.73* 3.75px;
      border-radius: 1* 3.75px;
      font-size: 3.73* 3.75px;
      color: rgba(255, 255, 255, 1);
      text-align: center;
    }
  }
</style>
